<template>

<div style="z-index: 1111;" class="container"
:class="[ !verificationModal && !informationalModal  ? ['position-fixed','top-50','start-50','translate-middle'] : '']">
<div class="row">
<div class="col-12 mx-auto responsive_card_wrapper">

<div class="modal_container scale-up-center">
<div class="text-center pt-5  modal_body modal_messages">

<p class="modal_messages_persian">{{modalMessagePersian}}</p>
<p class="">{{modalMessageEnglish}}</p>

<div v-if="verificationModal" class="mx-auto  bg-primary mt-5 py-5 text-center">
            
<button  type="button"
 @click.prevent="adminModalAnswer('yes')"
 class="btn btn-dark mx-3 px-5 modal_button">
<span class="d-block">Yes</span>
</button>

<button type="button"
 @click.prevent="adminModalAnswer('no')"
 class="btn btn-dark mx-3 px-5 modal_button">
<span class="d-block">No</span>
</button>

</div>

<div v-if="!verificationModal"
 class="mx-auto bg-primary mt-5 py-5 text-center">
            
<button ref="btnModal" type="button"
 @click.prevent="closeModal()"
 class="btn btn-dark px-5 modal_button">
<span class="d-block">آگاه شدم</span>
<span class="d-block">I Understood</span>
</button>

</div>

</div>
</div>

</div>
</div>
</div>


</template>

<script>

export default {
name: 'AppModal',

computed: {

showModal: function() {
return this.$store.getters.showModal;
},

modalMessagePersian: function() {
return this.$store.getters.modalMessagePersian;
},

modalMessageEnglish: function() {
return this.$store.getters.modalMessageEnglish;
},

appError: function() {
return this.$store.getters.appError;
},

verificationModal: function() {
return this.$store.getters.verificationModal;
},

authenticationModal: function() {
return this.$store.getters.authenticationModal;
},

informationalModal: function() {
return this.$store.getters.informationalModal;
},

contactModal: function() {
return this.$store.getters.contactModal;
},

adminVerificationCase: function() {
return this.$store.getters.adminVerificationCase;
},

reloadPage: function() {
return this.$store.getters.reloadPage;
},

},

methods: {
   
closeModal: function() {

if (this.reloadPage || this.appError) {

this.$router.go(0);  //refresh the page

} else if (this.adminVerificationCase == "AddNewSong") {

this.$router.push({ path: '/Admin/AllSongs' });
this.$store.commit('hideModal');

} else if (this.adminVerificationCase == "AddNewVideo") {

this.$router.push({ path: '/Admin/AllVideos' });
this.$store.commit('hideModal');

} else {

this.$store.commit('hideModal');

}

},


adminModalAnswer: function(answer) {

if (answer == 'yes') {

switch (this.adminVerificationCase) {

  case "CancelEdits":
  this.$emit('cancel-edits');
  this.$store.commit('hideModal');
  break;

  case "SaveEdits":
  this.$emit('save-edits');
  break;

  case "AddNewSong":
  this.$emit('add-new-song');
  break;

  case "AddNewVideo":
  this.$emit('add-new-video');
  break;

  case "DeleteSong":
  this.$emit('delete-song');
  break;

  case "DeleteVideo":
  this.$emit('delete-video');
  break;

}

} else {

this.$store.commit('hideModal');

}

},

},

}

</script>


<style scoped>

.modal_messages {

font-size: 1.7rem;
font-family: "iranyekan";
background: #ffbb05;
color: #000000;
line-height: 1.3; 
}

.modal_messages_persian {

font-family: "iranyekan";
}

.modal_button {

font-size: 1.4rem;
border-radius: 1rem;
padding: 0.7rem 1.8rem;
font-family: "iranyekan";
font-weight: 700;
}

.modal_button:hover {

background-color: #e5e5e5;
color: #000;
}

.modal_button:active {

background-color: #ffbb05;
color: #000;
}

.modal_button:focus-visible {

outline-color: #000;
outline-width: 1px;
}
.modal_container {

position: relative;
z-index: 9999;
}

.modal_body {

position: absolute;
left: 0;
right: 0;
bottom: 0;

}

/******** modal animation ********/

.scale-up-center {
	-webkit-animation: scale-up-center 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/******************* Media Queries // min 768px ******************/

@media (min-width: 767.98px) {

.modal_messages {

font-size: 1.9rem;
font-weight: 600;
}

.modal_button {

font-size: 1.7rem;
}

}

</style>
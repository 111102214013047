<template>

<div>

<a href="#" class="btn btn-lg btn-warning p-0 d-flex justify-content-center
 align-items-center back_to_top_button">
<i class="fa-solid fa-arrow-up"></i>
</a>

</div>

</template>

<script>
export default {
name: "BackToTopButton",
};

</script>


<style scoped>


.back_to_top_button {

position: absolute;
/* position: fixed; */
right: 20px;
bottom: 180px;
font-size: 2rem;
color: #000000;
z-index: 999;
width: 48px;
height: 48px;
font-weight: normal;
border-radius: 50px;
transition: 0.2s;
}


</style>
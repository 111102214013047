<template>
<header>

<div class="container-fluid">
<div class="row justify-content-sm-around justify-content-lg-evenly">

<div dir="ltr" class="col-6 col-sm-5 col-lg-4 site_title_english_container">
<h5 class="text-start site_title_english">Misagh Karimi</h5>
<h6 class="text-end site_description_english">Music Portfolio</h6>
</div>

<div dir="rtl" class="col-6 col-sm-5 col-lg-4 site_title_persian_container">
<h5 class="text-end site_title_persian">میثاق کریمی</h5>
<h6 class="text-start site_description_persian">آثار موسیقی</h6>
</div>

</div>
</div>

</header>
</template>

<script>
export default {
name: "AppHeader",
};

</script>

<style scoped>

.site_title_english_container {

font-weight: bold;
color:#fff;
font-family: 'Segoe UI';
border-left-style: none;
border-bottom-style: solid;
border-right-style: none;
border-color: #000000;
border-bottom-right-radius: 0;
border-top-right-radius: 0;
border-bottom-left-radius: 5rem;
border-width: 0.5rem;
box-shadow: 2px 1px 4px 1px #bfbfbf;
text-shadow: 4px 5px 5px rgba(0, 0, 0, 0.75),
-3px 5px 6px rgba(0, 0, 0, 0.75);
}

.site_title_english {

padding-left: 0.5rem;
padding-top: 1.1rem;
padding-bottom: 2.3rem;
font-size: 1.8rem;
}
.site_description_english {

font-size: 1.4rem;
padding-right: 0.4rem;
padding-bottom: 1rem;
}

.site_title_persian_container {

font-weight: bold;
color:#fff;
font-family: iranyekan;
border-right-style: none;
border-bottom-style: solid;
border-left-style: solid;
border-color: #000000;
border-bottom-left-radius: 0;
border-top-left-radius: 0;
border-bottom-right-radius: 5rem;
border-width: 0.5rem;
box-shadow: -2px 1px 4px 1px #bfbfbf;
text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.75),
-3px 5px 6px rgba(0, 0, 0, 0.75)
}

.site_title_persian {

padding-right: 0.5rem;
padding-top: 1rem;
padding-bottom: 2.1rem;
font-size: 1.7rem;
}

.site_description_persian {

font-size: 1.4rem;
padding-top: 0.3rem;
} 


/******************* Media Queries // min 576px ******************/

@media (min-width: 575.98px) {

.site_title_english_container {

font-family: 'Segoe UI';
border-bottom-style: solid;
border-left-style: solid;
border-color: #a7a7a7;
border-bottom-left-radius: 5rem;
border-bottom-right-radius: 3rem;
border-width: 0.3rem;
box-shadow: -4px 4px 4px 0px #000;
text-shadow: 6px 5px 5px rgba(0, 0, 0, 0.75),
-3px 5px 6px rgba(0, 0, 0, 0.75);
}

.site_title_english {

font-size: 2rem; 
padding-top: 1.2rem;
}

.site_description_english {

padding-right: 0.8rem;
padding-top: 1rem;
}

.site_title_persian_container {

font-family: iranyekan;
border-left-style: none;
border-bottom-style: solid;
border-right-style: solid;
border-color: #a7a7a7;
border-bottom-right-radius: 5rem;
border-bottom-left-radius: 3rem;
border-width: 0.3rem;
box-shadow: 4px 4px 4px 0px #000;
text-shadow: 6px 5px 5px rgba(0, 0, 0, 0.75),
-3px 5px 6px rgba(0, 0, 0, 0.75)
}

.site_title_persian {

font-size: 2rem;
padding-top: 1.2rem;
}

.site_description_persian {

padding-top: 1rem;
padding-left: 1rem ;
font-size: 1.5rem;
}

}

/******************** Media Queries // min 768px *******************/

@media (min-width: 767.98px) {

.site_title_english {

padding-top: 2rem;
font-size: 2.1rem; 
}

.site_description_english {

font-size: 1.6rem;
}

.site_title_persian {

padding-top: 2rem;
font-size: 2.1rem;
}

.site_description_persian {

font-size: 1.6rem;
}

}

/******************* Media Queries // min 992px *******************/

@media (min-width: 991.98px) {

.site_description_english {

font-size: 1.7rem;
}

.site_description_persian {

font-size: 1.7rem;
}

}

</style>
<template>

<div class="position-relative">
<div class="mx-auto position-fixed top-50 start-50 translate-middle">
<div class="loading_spinner_component position-relative">
</div>
<span v-if="isUploading" class="upload_percent">{{ uploadPercent +"  %" }}</span>
</div>
</div>

</template>

<script>

export default {
name: "TheLoadingSpinner",


computed: {

uploadPercent: function() {
return this.$store.getters.uploadPercent;
},

isUploading: function() {
return this.$store.getters.isUploading;
},

}

};



</script>

<style scoped>

.loading_spinner_component {

width: 80px;
height: 80px;
border-radius: 50%;
background-color: transparent;
border: 7px solid #222;
border-top: 7px solid #ffbb05;
border-bottom: 7px solid #ffbb05;
-webkit-animation: 1s spin linear infinite;
animation: 1s spin linear infinite;
margin-bottom: 15px;

}

.upload_percent {

background-color: black;
font-size: 25px;
margin-left: 15px;

}


/*
 * -----------------------
 * Loading Spinner animation
 * -----------------------
 */

@-webkit-keyframes spin {

from {
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
}
to {
-webkit-transform: rotate(360deg);
transform: rotate(360deg);
}

}
          
@keyframes spin {

from {
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
}
to {
-webkit-transform: rotate(360deg);
transform: rotate(360deg);
}

}    

</style>
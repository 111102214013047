<template>
<div id="app">

<transition name="SlideDown" appear>
<AppHeader v-if="showHeader"></AppHeader>
</transition>

<transition name="SlideDown" appear>
<AppNavigation v-if="showNavigation"></AppNavigation>
</transition>

<AppModal v-if="showModal && appError || contactModal || authenticationModal ||
 showMaintenanceMessage && !adminSide" >
</AppModal>

<div class="overflow-hidden">
<keep-alive>
<router-view  class="fade-in-right"></router-view> 
</keep-alive>
</div>

<BackToTopButton v-if="showBackToTopBtn"></BackToTopButton>

<AppFooter ></AppFooter>

<TheLoadingSpinner v-if="showLoadingSpinner"></TheLoadingSpinner>

</div>
</template>

<script>

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/reset.css';
import '@/assets/css/fontawesome.min.css';
import '@/assets/css/solid.min.css';
import '@/assets/css/brands.min.css';
import '@/assets/css/vue-plyr.css';
import '@/assets/css/global.css';

import AppHeader from '@/GlobalComponents/AppHeader.vue';
import AppNavigation from '@/GlobalComponents/AppNavigation.vue';
import AppFooter from '@/GlobalComponents/AppFooter.vue';
import AppModal from '@/GlobalComponents/AppModal.vue';
import TheLoadingSpinner from '@/GlobalComponents/TheLoadingSpinner.vue';
import BackToTopButton from '@/pages/AdminPage/Child/BackToTopButton.vue';

export default {
name: "App",
components: {AppHeader, AppNavigation, AppFooter, AppModal, TheLoadingSpinner, BackToTopButton},

computed: {

showModal: function() {
return this.$store.getters.showModal;
},

showMaintenanceMessage: function() {
return this.$store.getters.showMaintenanceMessage;
},

contactModal: function() {
return this.$store.getters.contactModal;
},

authenticationModal: function() {
return this.$store.getters.authenticationModal;
},

appError: function() {
return this.$store.getters.appError;
},

showLoadingSpinner: function() {
return this.$store.getters.showLoadingSpinner;
},

showHeader: function() {

return this.$route.name == 'Default' ||
       this.$route.name == 'SongsPage' ||
       this.$route.name == 'VideosPage' ||
       this.$route.name == 'ContactPage';

},

showNavigation: function() {

return this.$route.name == 'Default' ||
       this.$route.name == 'SongsPage' ||
       this.$route.name == 'VideosPage' ||
       this.$route.name == 'ContactPage';
},

showFooter: function() {

return this.$route.name == 'Default' ||
       this.$route.name == 'SongsPage' ||
       this.$route.name == 'VideosPage' ||
       this.$route.name == 'ContactPage';
},


showBackToTopBtn: function() {

return this.$route.name !== 'LoginForm' &&
       this.$route.name !== 'RegisterForm' &&
       this.$route.name !== 'ForgotPassword' &&
       this.$route.name !== 'ResetPassword' &&
       this.$route.name !== 'VerifyCode'
},

adminSide: function() {

return this.$route.fullPath !== '/' &&
       this.$route.fullPath !== '/Singles' &&
       this.$route.fullPath !== '/Videos' &&
       this.$route.fullPath !== '/Contact' &&
       this.$route.fullPath !== '/singles' &&
       this.$route.fullPath !== '/videos' &&
       this.$route.fullPath !== '/contact';


},

},

async created () {

try {

await this.$store.dispatch('getSiteSettings');
await this.$store.dispatch('getSongsData');
await this.$store.dispatch('getVideosData');

} catch (error) {
throw new Error(error);
}

},

mounted() {

window.addEventListener('load', () => {

this.$store.commit('hideLoadingSpinner')
})

}

};

</script>


<style scoped>
</style>

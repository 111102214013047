
import axios from 'axios';
import jwt_decode from "jwt-decode";
import {decryptData} from '@/services/encryption.js';

const sharedModule = {

state: {

showLoadingSpinner: false,
showMaintenanceMessage: false,
maintenanceMessageEnglish: '',
maintenanceMessagePersian: '',
appError: false,
showModal: false,
modalMessagePersian: null,
modalMessageEnglish: null,
informationalModal: false,
isBlur: false,
reloadPage: false

},

mutations: {

reloadPage (state) {
state.reloadPage = true;
},

toggleBlur (state) {
state.isBlur = !state.isBlur;
},

hideLoadingSpinner (state) {
state.showLoadingSpinner = false;
},

showLoadingSpinner (state) {
state.showLoadingSpinner = true;
},

showModal (state, payload) {
state.showModal = true;
state.modalMessagePersian = payload.modalMessagePersian;
state.modalMessageEnglish = payload.modalMessageEnglish;
state.appError = payload.appError;
state.reloadPage = payload.reloadPage;
state.informationalModal = payload.informationalModal;
this.state.Contact.contactModal = payload.contactModal;
this.state.Content.verificationModal = payload.verificationModal;
this.state.Content.adminVerificationCase = payload.adminVerificationCase;
this.state.Content.songDatabaseId = payload.songDatabaseId;
this.state.Content.videoDatabaseId = payload.videoDatabaseId;
this.state.Content.editingSongIndex = payload.editingSongIndex;
this.state.Content.editingVideoIndex = payload.editingVideoIndex;
this.state.Auth.authenticationModal = payload.authenticationModal;
},

hideModal (state) {
state.showModal = false;
state.appError = false;
state.informationalModal = false;
state.reloadPage = false;
this.state.Content.editingSongIndex = null;
this.state.Content.editingVideoIndex = null;
this.state.Content.verificationModal = false;
this.state.Auth.authenticationModal = false;
this.state.Contact.contactModal = false;
},


updateSiteSettingsData (state, payload) {

state.showMaintenanceMessage = payload.showMaintenanceMessage;
state.maintenanceMessageEnglish = payload.maintenanceMessageEnglish;
state.maintenanceMessagePersian = payload.maintenanceMessagePersian;
state.modalMessageEnglish = payload.maintenanceMessageEnglish;
state.modalMessagePersian = payload.maintenanceMessagePersian;
state.reloadPage = payload.showMaintenanceMessage;

},


},


getters: {

informationalModal (state) {
return state.informationalModal;
},

modalMessagePersian (state) {
return state.modalMessagePersian;
},

modalMessageEnglish (state) {
return state.modalMessageEnglish;
},

showLoadingSpinner (state) {
return state.showLoadingSpinner;
},

showModal (state) {
return state.showModal;
},

showMaintenanceMessage (state) {
return state.showMaintenanceMessage;
},

siteSettings (state) {

let siteSettings = {
'showMaintenanceMessage': state.showMaintenanceMessage,
'maintenanceMessageEnglish': state.maintenanceMessageEnglish,
'maintenanceMessagePersian': state.maintenanceMessagePersian
}

return siteSettings;
},


appError (state) {
return state.appError;
},

reloadPage (state) {
return state.reloadPage;
},

isBlur (state) {
return state.isBlur;
},

userProfile (state, rootState) {

if (rootState.encryptedAccessToken) {

const decryptedAccessToken  = decryptData(rootState.encryptedAccessToken);
const decodedAccessToken = jwt_decode(decryptedAccessToken);
 
return decodedAccessToken.accessTokenContent;

} else {

return '';

}

},

},


actions: {

async getSiteSettings({ commit }) {

try {

const responseResult = await axios.get(process.env.VUE_APP_BASE_URL + '/Api/Settings/GetSettings');

if (responseResult.data.operation == 'success') {

commit("updateSiteSettingsData", responseResult.data.siteSettingsData);

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

}

} catch (error) {
throw new Error(error);
}

},


async handleSettingEditSubmit ({ commit, getters, dispatch }, payload) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
siteSettingsID: getters.userProfile.siteSettingsID,
showMaintenanceMessage: payload.showMaintenanceMessage,
maintenanceMessageEnglish: payload.maintenanceMessageEnglish,
maintenanceMessagePersian: payload.maintenanceMessagePersian
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Settings/EditSettings`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == "edit-settings-done") {

commit("updateSiteSettingsData", responseResult.data.updatedSiteSettings);

modalMessageEnglish = "The changes have been saved";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});


} else if (responseResult.data.operation == "create-settings-done") {

commit("updateSiteSettingsData", responseResult.data.createdSiteSettings);

modalMessageEnglish = "The changes have been saved";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});


await dispatch('saveEncryptedAccessToken', responseResult.data.accessToken);

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},



async handleProfileEditSubmit ({ commit, dispatch }, name) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
name: name,
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Settings/EditProfile/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == "edit-profile-done") {

await dispatch('saveEncryptedAccessToken', responseResult.data.accessToken);

modalMessageEnglish = "The changes have been saved";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},

}

}

export default sharedModule;
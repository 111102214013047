import Vue from 'vue';
import Vuex from 'vuex';

import authenticationModule from './authenticationModule.js';
import contentModule from './contentModule.js';
import contactModule from './contactModule.js'
import sharedModule from './sharedModule.js';

Vue.use(Vuex);
Vue.config.devtools = true

export default new  Vuex.Store({
modules: {
Auth: authenticationModule,
Content: contentModule,
Contact: contactModule,
Shared: sharedModule
},
});


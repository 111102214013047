
import {encryptData, decryptData} from '@/services/encryption.js';
import axios from 'axios';
import router from '@/router.js';

const authenticationModule = {

state: {

authenticationModal: false,
encryptedAccessToken: null,

},

mutations: {

showLoadingSpinner (state) {
state.showLoadingSpinner = true;
},


setAccessToken (state, encryptedAccessToken) {
state.encryptedAccessToken = encryptedAccessToken;
},

},


getters: {

encryptedAccessToken (state) {
return state.encryptedAccessToken;
},

authenticationModal (state) {
return state.authenticationModal;
},


},


actions: {

async handleRegisterSubmit ({ commit }, registerData) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post",  
data: registerData,
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/Signup/`,
});

commit('hideLoadingSpinner');

if (responseResult.data.operation == 'code-sent') {

router.push({ name: 'VerifyCode', params: { unverifiedEmail: registerData.unverifiedEmail } });

} else {

let modalMessageEnglish = '';

if (responseResult.data.operation == 'invalid-invitation-key') {

modalMessageEnglish = 'The invitation key is invalid';

} else if (responseResult.data.operation == 'verify-code-sending-limit') {

modalMessageEnglish = 'Signing up with this email is temporarily blocked';

} else if (responseResult.data.operation == 'user-existed') {

modalMessageEnglish = 'Email address is already used. please enter another one';

}

commit("showModal", {
modalMessageEnglish: modalMessageEnglish || 'An error occurred! please try again',
authenticationModal: true,
});

}

} catch (error) {
throw new Error(error);
}

},


async handleVerifyCodeSubmit ({ commit }, payload) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post",  
data: {
verifyCode: payload.enteredCode,
unverifiedEmail: payload.unverifiedEmail,
},
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/VerifyEmail/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == 'success') {

modalMessageEnglish = 'your account has been successfully activated. please Login';

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

router.push({ name: 'LoginForm' });

} else if (responseResult.data.operation == 'wrong-code') {

modalMessageEnglish = 'The entered code is wrong! please enter again';

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},



async handleResendVerifyCode ({ commit }, payload) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post",  
data: {
unverifiedEmail: payload.unverifiedEmail,
resendVerifyCode: true
},
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/VerifyEmail/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == "resend-code-done") {

modalMessageEnglish = "The verfiy code has been sent again";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

return 'resend-code-done';

} else if (responseResult.data.operation == "verify-code-sending-limit") {

modalMessageEnglish = "Sending more codes to this email is temporarily blocked";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},


async handleLogoutSubmit ({ commit }) {

try {

commit('showLoadingSpinner');

await axios({
method: "post",  
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/Logout/`,
});

commit('hideLoadingSpinner');
await localStorage.removeItem("content");
delete axios.defaults.headers.common['Authorization']
router.push({ name: 'LoginForm' });

} catch (error) {
throw new Error(error);
}

},


async handleLoginSubmit ({ commit, dispatch }, loginData) {

try {

let modalMessageEnglish = '';
commit('showLoadingSpinner');

const responseResult = await axios({
method: "post",  
data: loginData,
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/Signin/`,
});

commit('hideLoadingSpinner');

if (responseResult.data.operation == 'success') {

await dispatch('saveEncryptedAccessToken', responseResult.data.accessToken);

axios.defaults.headers.common['Authorization'] = `Bearer ${responseResult.data.accessToken}`;

router.push({ path: '/Admin/Dashboard' });

} else if (responseResult.data.operation == 'failed') {

modalMessageEnglish = 'The email address or the password may be incorrect';

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

} else {

commit("showModal", {
modalMessageEnglish: modalMessageEnglish || 'An error occurred! please try again',
authenticationModal: true,
});

}

} catch (error) {
throw new Error(error);
}

},



async handleForgotPasswordSubmit ({ commit }, requestData) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post",  
data: requestData,
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/ForgotPassword/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == "done-if-account-available") {

modalMessageEnglish = "We sent email if account exists. please check you email";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

router.push({ name: 'LoginForm' });

} else if (responseResult.data.operation == "reset-pass-sending-limit") {

modalMessageEnglish = "Processing more requests are temporarily blocked";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},


async checkAccessTokenValidation({ commit, dispatch, state }) {

try {

const decryptedAccessToken  = await decryptData(state.encryptedAccessToken);

axios.defaults.headers.common['Authorization'] = `Bearer ${decryptedAccessToken}`;

const responseResult = await axios({
method: "post",  
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/CheckAccessToken/`,
});

if (responseResult.data.operation == 'valid-token') {

return {'result': 'valid-token', 'accessToken': decryptedAccessToken};

} else if (responseResult.data.operation == 'invalid-but-refreshed'){

await dispatch('saveEncryptedAccessToken', responseResult.data.accessToken);

return {'result': 'invalid-but-refreshed', 'accessToken': responseResult.data.accessToken};

} else {

commit('setAccessToken', null);
await localStorage.removeItem("content");
delete axios.defaults.headers.common['Authorization']

return 'invalid-token';

}

} catch (error) {
commit('setAccessToken', null);
await localStorage.removeItem("content");
delete axios.defaults.headers.common['Authorization']
throw new Error(error);
}

},



async checkResetPassToken ({ commit }, resetPassToken) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
resetPassToken: resetPassToken,
}, 
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/CheckResetPassToken/`,
});

commit('hideLoadingSpinner');

let modalMessageEnglish = '';

if (responseResult.data.operation == "reset-pass-token-valid") {

return "reset-pass-token-valid";

} else if (responseResult.data == "Invalid Request")  {

modalMessageEnglish = "Reset Password session is invalid or expired, please start again";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

return "reset-pass-token-invalid";

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

return "reset-pass-token-invalid";

} 

} catch (error) {
throw new Error(error);
}

},


async handleResetPasswordSubmit ({ commit }, payload) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
credentials: "include",
withCredentials: true,
data: {
newPassword: payload.newPassword,
resetPassToken: payload.resetPassToken,
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/ResetPassword/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == "reset-pass-done") {

commit('hideLoadingSpinner');

modalMessageEnglish = "your new password has been submitted successfully you can sign-in now";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

await commit('setAccessToken', null);
await localStorage.removeItem("content");
delete axios.defaults.headers.common['Authorization']

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

commit('setAccessToken', null);
await localStorage.removeItem("content");
delete axios.defaults.headers.common['Authorization']

} 

} catch (error) {
throw new Error(error);
}

},


async handleChangePasswordSubmit ({ commit }, passwords) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
currentPassword: passwords.currentPassword,
newPassword: passwords.newPassword,
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/ChangePassword/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == "current-pass-wrong") {

modalMessageEnglish = "your current password is wrong, please try again";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

} else if (responseResult.data.operation == "change-pass-done") {

modalMessageEnglish = "your new password has been changed successfully";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
reloadPage: true,
});

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},






async getInvitationKeys ({ commit }) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
operation: 'getKeys',
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/Keys/`,
});

commit('hideLoadingSpinner');

if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to delete a key",
informationalModal: true,
});

return 'no-permission';

} else if (responseResult.data.keys) {

return {'keys': responseResult.data.keys};

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},



async handleGenerateInvitationKey ({ commit }) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
operation: 'generateKey',
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/Keys/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to delete a key",
authenticationModal: true
});

return 'no-permission';

} else if (responseResult.data.operation == "key-generated-done") {

modalMessageEnglish = "Done! A new key has been added to the list";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

return {'updatedKeys': responseResult.data.updatedKeys};

} else if (responseResult.data.operation == "maximum-keys") {

modalMessageEnglish = "Maximum number of keys reached";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

return 'maximum-keys';

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},


async handleDeleteInvitationKey ({ commit }, key) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "post", 
data: {
operation: 'deleteKey',
key: key,
},  
url: process.env.VUE_APP_BASE_URL + `/Api/Auth/Keys/`,
});

commit('hideLoadingSpinner');
let modalMessageEnglish = '';

if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to delete a key",
informationalModal: true,
});

return 'no-permission';

} else if (responseResult.data.operation == "key-deleted-done") {

modalMessageEnglish = "Done! the selected key has been deleted";

commit("showModal", {
modalMessageEnglish: modalMessageEnglish,
authenticationModal: true,
});

return {'updatedKeys': responseResult.data.updatedKeys};

} else {

commit("showModal", {
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

} 

} catch (error) {
throw new Error(error);
}

},



async saveEncryptedAccessToken({ commit }, accessToken) {

try {

axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

const encryptedAccessToken = await encryptData(accessToken);

commit('setAccessToken', encryptedAccessToken);

localStorage.setItem('content', encryptedAccessToken)

} catch (error) {
throw new Error(error);
}

},


async loadEncryptedAccessToken({ commit }) {

try {

if (localStorage.getItem('content')) {

const encryptedAccessToken = await  localStorage.getItem('content');

commit('setAccessToken', encryptedAccessToken);

const decryptedAccessToken  = await decryptData(encryptedAccessToken);

axios.defaults.headers.common['Authorization'] = `Bearer ${decryptedAccessToken}`;

} else {

commit('setAccessToken', null);

}

} catch (error) {
throw new Error(error);
}

},

},

}

export default authenticationModule;
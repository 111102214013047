'use strict';
 
import CryptoJS from 'crypto-js';


function encryptData(data) {
const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_ENCRYPTION_KEY).toString();
return encrypted;
}

function decryptData(encryptedData) {
const decrypted = CryptoJS.AES.decrypt(encryptedData, process.env.VUE_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
return JSON.parse(decrypted);
}


export {encryptData, decryptData};


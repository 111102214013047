
import axios from 'axios';

const contactModule = {

state: {

contactModal: false

},

getters: {

contactModal (state) {
return state.contactModal;
},


},

actions: {


async handleContactFormSubmit({ commit }, payload) {

commit("toggleBlur");
commit('showLoadingSpinner');

try {

const responseResult = await axios({
method: "post",
url: process.env.VUE_APP_BASE_URL + "/Api/Contact/",
data:{
googleToken: payload.googleToken,
visiterName: payload.visiterName,
visiterEmailAddress: payload.visiterEmailAddress,
messageContent: payload.messageContent,
},
});

commit('hideLoadingSpinner');

if (responseResult.data.operation == "message-sent") {

commit("showModal", {
modalMessagePersian: 'پیام شما با موفقیت ارسال شد',
modalMessageEnglish: 'Your message was successfully sent',
contactModal: true,
reloadPage:true,
});

} else {

commit("showModal", {
modalMessagePersian: 'پیام ارسال نشد! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'Message Not Sent! please try again',
contactModal: true,
});

commit("toggleBlur");

}

} catch (error) {
throw new Error(error);
}

},

},

}

export default contactModule;
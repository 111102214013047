<template>
<main>
<section v-if="!showMaintenanceMessage || adminSide">

<SkeletonComponent v-for="(skeleton, index) in songsData" v-show="!loadedSongsList.includes(index)"
  ref="SongSkeletonComponentRefs" 
 :key="skeleton.index">
</SkeletonComponent>

<SongComponent v-show="loadedSongsList.includes(index)" v-for="(song, index) in songsData"
  ref="songComponentRefs" 
 :key="index" 
 :songDescription="song.songDescription"
 :songImageSrcJpg="song.imgSrcJpeg"
 :songMp3FileSrc="song.songMp3FileSrc"
 :songIndex="index"
 :songDatabaseId="song._id"
 :isAdminPage="isAdminPage"
 @when-a-song-starts-playing="stopPlayingOtherSongs"
 @when-song-component-is-fully-loaded="addSongToLoadedList">
</SongComponent>

</section>
</main>
</template>

<script>

const SongComponent = () => import('@/pages/SongsPage/Child/SongComponent/SongComponent.vue')
const SkeletonComponent = () => import('@/pages/SharedComponents/SkeletonComponent.vue')

export default {
name: "SongsPage",

components: { SongComponent, SkeletonComponent},

props: {

isAdminPage: {
type: Boolean,
default: false,
}

},

data() {
return {

loadedSongsList: [],

}
},

computed: {

songsData: function() {
return this.$store.getters.songsData;
},

showMaintenanceMessage: function() {
return this.$store.getters.showMaintenanceMessage;
},

adminSide: function() {

return this.$route.fullPath !== '/' &&
       this.$route.fullPath !== '/Singles' &&
       this.$route.fullPath !== '/Videos' &&
       this.$route.fullPath !== '/Contact' &&
       this.$route.fullPath !== '/singles' &&
       this.$route.fullPath !== '/videos' &&
       this.$route.fullPath !== '/contact';
},

},

methods: {

stopPlayingOtherSongs: function(currentlyPlayingSongIndex) {

for (let i = 0; i < this.songsData.length; i++) {

if (this.$refs.songComponentRefs[i] != this.$refs.songComponentRefs[currentlyPlayingSongIndex]) {

this.$refs.songComponentRefs[i].stopSong();

}
}

},


async addSongToLoadedList (index) {

try {

await this.loadedSongsList.push(index);

} catch (error) {
throw new Error(error);
}

},

},

};

</script>

<style scoped>
</style>

import store from '../store/index.js';
import router from '../router.js';
import axios from 'axios';

// HTTP request interceptor
axios.interceptors.request.use(
async function (config) {
// Check if the API endpoint requires authorization
const needsAuth = ['Add', 'Edit', 'Delete', 'ChangePassword', 'Keys', 'Logout'].some((keyword) =>
config.url.includes(keyword)
);

if (needsAuth) {
const accessTokenValidationResult = await store.dispatch('checkAccessTokenValidation');

// Set the authorization header if the token is valid or refreshed
if (['valid-token', 'invalid-but-refreshed'].includes(accessTokenValidationResult.result)) {
config.headers.Authorization = `Bearer ${accessTokenValidationResult.accessToken}`;
} else {
config.headers.Authorization = '';
router.push({ path: '/Auth/Login' });
}
}

return config;
},
function (error) {
console.error('Axios error:', error);
// Handle request errors
return Promise.reject(error);
}
);

// HTTP response interceptor
axios.interceptors.response.use(
function (response) {
// Simply return the response for successful requests
return response;
},
function (error) {
console.error('Axios error:', error);
// Handle rate limiting (HTTP 429)
if (error.response.status === 429) {
store.commit('showModal', {
modalMessageEnglish: 'You are temporarily blocked because of too many requests',
modalMessagePersian: 'شما به علت ارسال درخواست های زیاد موقتا مسدود شده اید',
appError: true,
});
}

// Reject the promise to propagate the error
return Promise.reject(error);
}
);

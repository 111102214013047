
import Vue from "vue";
import App from "./App.vue";
import router from './router.js';
import store from './store/index.js';
import VuePlyr from 'vue-plyr';
import VueGtag from "vue-gtag";
import './services/requestResponseChecker.js'


new Vue({
store,
router,
render: (h) => h(App),
}).$mount("#app");

Vue.use(VuePlyr, {
plyr: {controls: ['play', 'progress',
'current-time', 'mute', 'volume', 'pip', 'airplay'] }
});

Vue.use(VueGtag, {
config: { id: "G-0Z6D6RZ1HQ" }
}, router);

Vue.config.errorHandler = function (err)  {
console.log('[Global Error Handler]: '+ err +': ' + err.stack);



const stringErr = err.toString();

if (!stringErr.includes('429')) {    // handled in requestResponseChecker.js 

store.commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

}

};

Vue.config.warnHandler = function(msg, vm, info) {
console.log('[Global Error Warner]: Error in ' + info + vm + ': ' + msg);
console.warn('Global warning handler:', msg);
};


<template>
<footer>

<div class="bg-dark footer_container">
<div class="container">
<div class="row">
<div class="col-12 mx-auto text-center footer_content">

<p>Copyright &copy; {{ currentYear + ' '  }} All Rights Reserved.</p>
<p>Web Developed By: Misagh Karimi</p>

<a href="https://instagram.com/misaghkarimi"><span><i class="fa-brands fa-instagram mx-3 all_logos"></i></span></a>

<a href="https://t.me/misaghkarimi"><span><i class="fa-brands fa-telegram mx-3 all_logos"></i></span></a>

<a href="https://www.youtube.com/@misaghkarimi_music"><span><i class="fa-brands fa-youtube mx-3 all_logos"></i></span></a>

</div>
</div>
</div>
</div>

</footer>
</template>

<script>


export default {
name: "AppFooter",

computed: {

currentYear () {
return new Date().getFullYear();
}

}
};

</script>


<style scoped>

.footer_container {

position: absolute;
bottom: 0;
width: 100%;
height: 160px;
border-style: solid;
border-left-style: none;
border-right-style: none;
border-color: #000000;
border-width: 0.4rem;
}

.footer_content  {

padding-top: 3rem;
color: #c0c0c0;
font-size: 1.4rem;
line-height: 1.7;
font-family: 'Segoe UI';
}

.all_logos {

color: #c0c0c0;
margin-top: 1rem;
font-size: 3.5rem;
text-align: center;
cursor: pointer;
transition: all 300ms ease-in-out;
-webkit-transition: all 300ms ease-in-out;
-moz-transition: all 300ms ease-in-out; 
-o-transition: all 300ms ease-in-out;
}

.all_logos:hover {

color: #ffbb05;
font-size: 4rem;
-webkit-transition: all 300ms ease-in-out;
transition: all 300ms ease-in-out;
-moz-transition: all 300ms ease-in-out; 
-o-transition: all 300ms ease-in-out;
}

</style>

<template>
<nav>

<div class="container">
<div class="row">

<div class="col-12 col-md-6 mx-auto nav_row">
<div class="d-flex justify-content-center ">

<div v-for="(navItem, index) in navItems" :key="index">

<router-link :to="navItem.routePath">

<div class="nav_item">
<img :src="require(`@/assets/img/nav-icons/${navItem.imgFileName}`)">
<h6 class="nav_text_english">{{ navItem.navTextEnglish }}</h6>
<h6 class="nav_text_persian">{{ navItem.navTextPersian }}</h6>
</div>

</router-link>

</div>

</div>
</div>

</div>
</div>

</nav>
</template>

<script>
export default {
name: "AppNavigation",

data() {
return {

navItems: [

{
routePath: "/Singles",
navTextEnglish: "Singles",
navTextPersian: "تک آهنگ",
imgFileName: "singles.png"
},

{
routePath: "/Videos",
navTextEnglish: "Videos",
navTextPersian: "ویدئو",
imgFileName: "videos.png"
},

{
routePath: "/Contact",
navTextEnglish: "Contact",
navTextPersian: "ارتباط",
imgFileName: "contact.png"
}

]

}
},

};

</script>


<style scoped>

.nav_row {

margin-top: 2.2rem;
margin-bottom: 7rem;
}

.nav_row a {

color: #fff;
cursor: pointer;
text-decoration: none;
}

.nav_row a:focus {   

color:#fff;
-webkit-text-stroke-color: #fff;
-webkit-text-stroke-width: 0.06rem; 
}

.nav_row a:hover,
.nav_row a:focus {   

color:#fff;
-webkit-text-stroke-color: #fff;
-webkit-text-stroke-width: 0.03rem; 
}

.nav_item {

width: 70px;
height: 70px;
border-radius: 50%;
text-align: center;
background-color: #ffbb05;
margin-left: 2rem;
margin-right: 2rem;
-moz-transition: all 0.2s linear;
-o-transition: all 0.2s linear;
-webkit-transition: all 0.2s linear;
transition: all 0.2s linear;
}

.nav_item img {

width: auto;
height: 70%;
margin-bottom: 0.5rem;
margin-top: 1rem;
}

.nav_item:hover {

background-color: #fff;
-moz-transition: all 0.2s linear;
-o-transition: all 0.2s linear;
-webkit-transition: all 0.2s linear;
transition: all 0.2s linear;
}

.nav_item:focus-visible {

outline: none;
}

.nav_text_english {

font-size: 1.7rem;
font-family: Sans-Serif;
margin-top: 1.8rem;
}

.nav_text_persian {

font-size: 1.4rem;
font-family: iranyekan;
line-height: 2.2;
}

.router-link-exact-active .nav_item {

background-color: #fff;
cursor: pointer;
}


/*
 * ---------------------------
 * AppNavigation vue Transition
 * ----------------------------
 */


.SlideDown-enter-active,
.SlideDown-leave-active {
transition: all 0.7s ease-out; 
-moz-transition: all 0.7s ease-out; 
-o-transition: all 0.7s ease-out; 
-webkit-transition: all 0.7s ease-out; 
}

.SlideDown-enter {

transform: translateY(-100%);
}

.SlideDown-enter-to {

transform: translateY(0);
}



/******************* Media Queries // min 480px ******************/

@media (min-width: 480px) {

.nav_item {

margin-left: 3rem;
margin-right: 3rem;
}

}

/******************* Media Queries // min 576px ******************/

@media (min-width: 575.98px) {

.nav_row {

margin-top: 5rem;
margin-bottom: 8rem;
}

.nav_item {

margin-left: 3.5rem;
margin-right: 3.5rem;
}

.nav_text_english {

font-size: 1.8rem;
}

.nav_text_persian {

font-size: 1.5rem;
}

}

/******************* Media Queries // min 768px ******************/

@media (min-width: 767.98px) {

.nav_row {

margin-bottom: 9rem;
}

.nav_text_english {

font-family: Sans-Serif;
}

.nav_text_persian {

font-family: iranyekan;
}

}


</style>
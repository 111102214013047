import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import store from './store/index.js';
import SongsPage from '@/pages/SongsPage/SongsPage.vue';

Vue.use(VueRouter);
Vue.use(RouterPrefetch);

const routes = [
{
path: '/',
name: 'Default',
component: SongsPage,
},
{
path: '/Singles',
name: 'SongsPage',
component: SongsPage,
},
{
path: '/Videos',
name: 'VideosPage',
component: () => import('@/pages/VideosPage/VideosPage.vue'),
},
{
path: '/Contact',
name: 'ContactPage',
component: () => import('@/pages/ContactPage/ContactPage.vue'),
},
{
path: '/Admin',
redirect: '/Admin/Dashboard',
},
{
path: '/Admin',
component: () => import('@/pages/AdminPage/AdminPage.vue'),
children: [
{
path: 'Dashboard',
component: () => import('@/pages/AdminPage/Child/AdminDashboardPage/AdminDashboardPage.vue'),
meta: { requiresAuth: true },
},
{
path: 'AllSongs',
component: () => import('@/pages/AdminPage/Child/AdminSongsPage/AdminSongsPage.vue'),
meta: { requiresAuth: true },
},
{
path: 'AddSongs',
component: () => import('@/pages/AdminPage/Child/AdminAddSongsPage/AdminAddSongsPage.vue'),
meta: { requiresAuth: true },
},
{
path: 'AllVideos',
component: () => import('@/pages/AdminPage/Child/AdminVideosPage/AdminVideosPage.vue'),
meta: { requiresAuth: true },
},
{
path: 'AddVideos',
component: () => import('@/pages/AdminPage/Child/AdminAddVideosPage/AdminAddVideosPage.vue'),
meta: { requiresAuth: true },
},
{
path: 'Settings',
component: () => import('@/pages/AdminPage/Child/AdminSettingsPage/AdminSettingsPage.vue'),
meta: { requiresAuth: true },
},
],
},
{
path: '/Auth/Register',
name: 'RegisterForm',
component: () => import('@/pages/Authentication/Child/RegisterForm.vue'),
},
{
path: '/Auth/Login',
name: 'LoginForm',
component: () => import('@/pages/Authentication/Child/LoginForm.vue'),
},
{
path: '/Auth/ForgotPassword',
name: 'ForgotPassword',
component: () => import('@/pages/Authentication/Child/ForgotPasswordForm.vue'),
},
{
path: '/Auth/ResetPassword/:resetPassToken',
name: 'ResetPassword',
component: () => import('@/pages/Authentication/Child/ResetPasswordForm.vue'),
},
{
path: '/Auth/VerifyCode',
name: 'VerifyCode',
component: () => import('@/pages/Authentication/Child/VerifyCodeForm.vue'),
},
{
path: '/:pathMatch(.*)*',
name: 'NotFound',
redirect: '/',
},
];

const router = new VueRouter({
mode: 'history',
routes,
});

router.beforeEach(async (to, from, next) => {

try {

if (to.path === '/Auth/Login') {

await store.dispatch('loadEncryptedAccessToken');

if (store.getters.encryptedAccessToken) {

next('/Admin/Dashboard');

} else {

next();

}

} else if (to.meta.requiresAuth) {

let encryptedAccessToken = store.getters.encryptedAccessToken;

if (!encryptedAccessToken) {

await store.dispatch('loadEncryptedAccessToken');

encryptedAccessToken = store.getters.encryptedAccessToken;

}

if (encryptedAccessToken) {

const accessTokenValidationResult = await store.dispatch('checkAccessTokenValidation');

if (['valid-token', 'invalid-but-refreshed'].includes(accessTokenValidationResult.result)) {

next();

} else {

next('/Auth/Login');

}

} else {

next('/Auth/Login');

}

} else if (to.name === 'ResetPassword') {

const resetPassTokenValidationResult = await store.dispatch('checkResetPassToken', to.params.resetPassToken);

if (resetPassTokenValidationResult === 'reset-pass-token-valid') {

next();

} else {

next('/Auth/ForgotPassword');

}

} else if (to.name === 'VerifyCode') {

if (from.name === 'RegisterForm') {

next();

} else {

next('/Auth/Register');

}

} else {

next();

}

} catch (error) {
throw new Error(error);
}

});

export default router;
